import axios from 'axios';
import authHeader from './auth/auth-header';

// const BASE_API_URL = process.env.REACT_APP_BASE_API_URL; // Questo dato è scritto nei file .env.
const BASE_API_URL = window.Configs?.JS_BASE_API_URL ? window.Configs.JS_BASE_API_URL : process.env.REACT_APP_BASE_API_URL; // Questo dato è scritto nei file .env.

const PROPERTY_ID = process.env.REACT_APP_PROPERTY_ID; // Questo dato è scritto nei file .env.
const API_URL = BASE_API_URL + 'vr/Reservations';

export class ReservationsService {

    getPublicReservations() {
        return axios.get(API_URL).then(res => res.data.data);
    }

    getReservations(villaId = PROPERTY_ID, params = '') {
        return axios.get(`${API_URL}/Details?propertyId=${villaId}&${params}`, {headers: authHeader()}).then(res => {
            return res.data;
        });
    }

    getInvalidDates() {
        return axios.get(`${API_URL}/InvalidDates`).then(res => {
            return res.data;
        });
    }

    getWebsiteDates(year, propertyId = 1) {
        return axios.get(`${API_URL}/Website/${propertyId}/${year}`).then(res => {
            return res.data;
        });
    }
/*
    getWebsiteDates(year) {
        return axios.get(`${API_URL}/Website/${year}`).then(res => {
            return res.data;
        });
    }
*/
    postReservation(reservation) {
        return axios.post(`${API_URL}`, reservation, {headers: authHeader()}).then(res => {
            return res.data;
        });
    }

    putReservation(reservation) {
        return axios.put(`${API_URL}/${reservation.id}`, reservation, {headers: authHeader()}).then(res => {
            return res.data;
        });
    }

    deleteReservation(id) {
        return axios.delete(`${API_URL}/${id}`, {headers: authHeader()}).then(res => {
            return true;
        });
    }

}
