import React, {useState, useEffect, useRef} from 'react';
import classNames from 'classnames';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
// import { FileUpload } from 'primereact/fileupload';
import {Toolbar} from 'primereact/toolbar';
import {InputTextarea} from 'primereact/inputtextarea';
import {RadioButton} from 'primereact/radiobutton';
import {InputNumber} from 'primereact/inputnumber';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {ReservationsService} from "../service/ReservationsService";
import {Calendar} from "primereact/calendar";
import {Dropdown} from "primereact/dropdown";


export const Reservations = () => {

    const localDataToUtc = (data) => {
        if (data instanceof Date) {
            const offset = new Date().getTimezoneOffset();
            const timezone = offset / -60;
            data.setHours(data.getHours() + timezone + 1);
            return data;
        }
    }

    let emptyReservation = {
        source_id: 1,
        guest_name: '',
        guest_lastname: '',
        guest_email: '',
        guest_phone: '',
        data_in: null,
        data_out: null,
        state_id: null,
        total_price: 11000,
        note: '',
        data_creation: localDataToUtc(new Date()),
        data_last_update: localDataToUtc(new Date()),
        user_id: 1,
        property_id: 1
    };

    const villas = [
        {optionValue: 1, optionLabel: 'Villa Renza'},
        {optionValue: 2, optionLabel: 'Villa Magnolia'}
    ]

    const statusIdValues = [
        {id: -1, label: 'Scaduta', title: 'Opzione scaduta'},
        {id: null, label: 'Da confermare', title: 'Proposta di opzione da confermare'},
        {id: 0, label: 'Opzione', title: 'Opzione con scadenza a 7 giorni'},
        {id: 1, label: 'Confermata', title: 'Opzione confermata in attesa di caparra'},
        {id: 2, label: 'Verificata', title: 'Prenotazione verificata con caparra ricevuta'}
    ]

    const sourceIdValues = [
        {agency_email: '', agency_name: 'Diretti', id: 1},
        {agency_email: '', agency_name: 'IC Bellagio', id: 2},
        {agency_email: '', agency_name: 'Happy Holiday Homes', id: 3},
        {agency_email: '', agency_name: 'Paola Colacicco', id: 4},
        {agency_email: '', agency_name: 'Arianna Sancassani', id: 5}
    ];
    const [invalidDates] = useState();
    const [arrStringYmd, setArrStringYmd] = useState({options: [], reservations: []})
    const [reservations, setReservations] = useState([]);
    const [showExpiredReservations, setShowExpiredReservations] = useState(true);
    const [reservationDialog, setReservationDialog] = useState(false);
    const [deleteReservationDialog, setDeleteReservationDialog] = useState(false);
    const [deleteReservationsDialog, setDeleteReservationsDialog] = useState(false);
    const [reservation, setReservation] = useState(emptyReservation);
    const [selectedReservations, setSelectedReservations] = useState(null);
    const [selectedVilla, setSelectedVilla] = useState(1);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);


    const reservationsService = new ReservationsService();


    useEffect(() => {
        const reservationsService = new ReservationsService();

        //reservationsService.getReservations().then(data => {
        //  setReservations(data);
        //});

        getReservations(selectedVilla);

        reservationsService.getInvalidDates().then(data => {
            setArrStringYmd({
                options: data.options.map(x => x.substr(0, 10)),
                reservations: data.reservations.map(x => x.substr(0, 10))
            });
        });
    }, []);

    const refreshResults = () => {
        getReservations();
    }

    const getReservations = (villaId = selectedVilla) => {
        reservationsService.getReservations(villaId).then(data => {
            setReservations(data);
        });
    }

    const getStatusLabel = (id) => {
        let label = 'Proposta di opzione'
        if(id) {
            label = statusIdValues.filter(x => x.id === id).map(x => x.label)[0];
        }
        return label;
    }

    const dateTemplate = (date) => {

        const workingDay = `${date.year}-${(date.month + 1).toLocaleString('it-IT', {minimumIntegerDigits: 2, useGrouping: false})}-${date.day.toLocaleString('it-IT', {minimumIntegerDigits: 2, useGrouping: false})}`;
        if (arrStringYmd.reservations.includes(workingDay)) {
            // console.log('res: ', workingDay);
            return <div style={{backgroundColor: 'red', color: '#ffffff', fontWeight: 'bold', borderRadius: '50%', width: '2em', height: '2em', lineHeight: '2em', padding: 0, textAlign: 'center'}}>{date.day}</div>;
        } else if (arrStringYmd.options.includes(workingDay)) {
            // console.log('opz: ', workingDay);
            return <div style={{backgroundColor: '#1dcbb3', color: '#ffffff', fontWeight: 'bold', borderRadius: '50%', width: '2em', height: '2em', lineHeight: '2em', padding: 0, textAlign: 'center'}}>{date.day}</div>
        } else {
            return date.day;
        }
    }

    const formatCurrency = (value) => {
        return value.toLocaleString('it-IT', {style: 'currency', currency: 'EUR'});
    }

    const toggleExpired = () => {
        // Nascondo le prenotazioni con data di check-out nel passato
        let showHide = !showExpiredReservations;

        if(showHide){
            getReservations(selectedVilla)
        } else {
            let today = new Date();
            setReservations([...reservations.filter(x => new Date(x.data_out) > today)]);
        }
        setShowExpiredReservations(!showExpiredReservations);
    }

    const openNew = () => {
        setReservation({...emptyReservation, property_id: selectedVilla});
        setSubmitted(false);
        setReservationDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setReservationDialog(false);
    }

    const hideDeleteReservationDialog = () => {
        setDeleteReservationDialog(false);
    }

    const hideDeleteReservationsDialog = () => {
        setDeleteReservationsDialog(false);
    }

    const saveReservation = () => {
        setSubmitted(true);

        if (reservation.data_in && reservation.data_out) {
            let _reservations = [...reservations];
            let _reservation = {...reservation};
            if (reservation.id) {
                const index = findIndexById(_reservations, reservation.id);

                reservationsService.putReservation(_reservation).then(data => {
                    _reservations[index] = data;
                    toast.current.show({severity: 'success', summary: 'Successful', detail: 'Reservation Updated', life: 3000});
                    setReservations(_reservations);
                });
            } else {
                // Mando al BE la nuova prenotazione e inserisco nell'array quello che mi viene restituito (con tanto di ID)
                reservationsService.postReservation(_reservation).then(data => {
                    _reservations.push(data);
                    toast.current.show({severity: 'success', summary: 'Successful', detail: 'Reservation Created', life: 3000});
                    setReservations(_reservations);
                });
            }

            setReservationDialog(false);
            setReservation(emptyReservation);

        }
    }

    const editReservation = (reservation) => {
        setReservation({...reservation});
        setReservationDialog(true);
    }

    const confirmDeleteReservation = (reservation) => {
        setReservation(reservation);
        setDeleteReservationDialog(true);
    }

    const deleteReservation = () => {
        if (reservationsService.deleteReservation(reservation.id)) {
            let _reservations = reservations.filter(val => val.id !== reservation.id);
            setReservations(_reservations);
            setDeleteReservationDialog(false);
            setReservation(emptyReservation);
            toast.current.show({severity: 'success', summary: 'Successful', detail: 'Reservation Deleted', life: 3000});
        }

    }

    const findIndexById = (arr, id) => {
        let index = -1;
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    // const createId = () => {
    //     let id = '';
    //     let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    //     for (let i = 0; i < 5; i++) {
    //         id += chars.charAt(Math.floor(Math.random() * chars.length));
    //     }
    //     return id;
    // }

    const exportCSV = () => {
        console.log(invalidDates);
        // setInvalidDates('test4');
        // getInvalidDates();
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteReservationsDialog(true);
    }

    const deleteSelectedReservations = () => {
        let _reservations = reservations.filter(val => !selectedReservations.includes(val));
        setReservations(_reservations);
        setDeleteReservationsDialog(false);
        setSelectedReservations(null);
        toast.current.show({severity: 'success', summary: 'Successful', detail: 'Reservations Deleted', life: 3000});
    }

    const onState_idChange = (e) => {
        let _reservation = {...reservation};
        _reservation['state_id'] = +e.value;
        setReservation(_reservation);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _reservation = {...reservation};
        _reservation[`${name}`] = val;

        setReservation(_reservation);
    }


    const onInputDataChange = (e, name) => {
        let val = (e.target && e.target.value) || '';
        val = localDataToUtc(val);
        let _reservation = {...reservation};
        _reservation[`${name}`] = val;
        setReservation(_reservation);
    }

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _reservation = {...reservation};
        _reservation[`${name}`] = val;

        setReservation(_reservation);
    }

    const onVillaChange = (e) => {
        setSelectedVilla(e.value);
        getReservations(e.value);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew}/>
                    <Button label="Delete" icon="pi pi-trash" className="p-button-danger  mr-2" onClick={confirmDeleteSelected} disabled={!selectedReservations || !selectedReservations.length}/>
                    <Button label="Old Reservations" icon={`${showExpiredReservations ? "pi pi-eye" : "pi pi-eye-slash"}`} className="p-button-secondary mr-2"  onClick={toggleExpired}/>
                    <Dropdown value={selectedVilla} options={villas}  onChange={(e) => onVillaChange(e)} optionLabel="optionLabel" optionValue="optionValue" placeholder="Select a Villa" />
                </div>
            </React.Fragment>
        )
    }
    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/*<FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" />*/}
                <Button label="Refresh" icon="pi pi-refresh" className="p-button-info mr-2" onClick={refreshResults}/>
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV}/>
            </React.Fragment>
        )
    }
    const idBodyTemplate = (rowData) => {
        return (
            <>
                {/*<span className="p-column-title">#</span>*/}
                {rowData.id}
            </>
        );
    }
    const nameBodyTemplate = (rowData) => {
        return (
            <>
                {/*<span className="p-column-title">Guest Name</span>*/}
                {rowData.guest_name}
            </>
        );
    }
    const lastnameBodyTemplate = (rowData) => {
        return (
            <>
                {/*<span className="p-column-title">Guest Last Name</span>*/}
                {rowData.guest_lastname}
            </>
        );
    }
    const sourceTemplate = (rowData) => {
        return (
            <>
                {/*<span className="p-column-title">Agency</span>*/}
                {sourceIdValues.find(x => x.id === rowData.source_id).agency_name}
            </>
        );
    }
    //
    // const emailBodyTemplate = (rowData) => {
    //     return (
    //         <>
    //             <span className="p-column-title">Guest eMail</span>
    //             {rowData.guest_email}
    //         </>
    //     );
    // }
    //
    // const phoneBodyTemplate = (rowData) => {
    //     return (
    //         <>
    //             <span className="p-column-title">Guest Phone</span>
    //             {rowData.guest_phone}
    //         </>
    //     );
    // }
    //
    // const noteBodyTemplate = (rowData) => {
    //     return (
    //         <>
    //             <span className="p-column-title">Note</span>
    //             {rowData.note}
    //         </>
    //     );
    // }
    const dataInBodyTemplate = (rowData) => {
        return (
            <>
                {/*<span className="p-column-title">CheckIn</span>*/}
                {new Date(rowData.data_in).toLocaleDateString()}
            </>
        )
    }
    const dataOutBodyTemplate = (rowData) => {
        return (
            <>
                {/*<span className="p-column-title">CheckOut</span>*/}
                {new Date(rowData.data_out).toLocaleDateString()}
            </>
        )
    }
    const priceBodyTemplate = (rowData) => {
        return (
            <>
                {/*<span className="p-column-title">Price</span>*/}
                {formatCurrency(rowData.total_price)}
            </>
        );
    }
    const statusBodyTemplate = (rowData) => {
        return (
            <>
                {/*<span className="p-column-title">Status</span>*/}
                {getStatusLabel(rowData.state_id)}
            </>
        );
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editReservation(rowData)}/>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteReservation(rowData)}/>
            </div>
        );
    }
    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Reservations <strong>{villas.filter(x => x.optionValue === selectedVilla)[0].optionLabel}</strong></h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search"/>
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..."/>
            </span>
        </div>
    );
    const reservationDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog}/>
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveReservation}/>
        </>
    );
    const deleteReservationDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteReservationDialog}/>
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteReservation}/>
        </>
    );
    const deleteReservationsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteReservationsDialog}/>
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedReservations}/>
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">

                <div className="card">
                    <Toast ref={toast}/>
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}/>

                    <DataTable
                        ref={dt}
                        value={reservations}
                        selection={selectedReservations}
                        onSelectionChange={(e) => setSelectedReservations(e.value)}
                        dataKey="id"
                        paginator
                        rows={25}
                        rowsPerPageOptions={[10, 25, 50]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} reservations"
                        globalFilter={globalFilter}
                        emptyMessage="No reservations found."
                        header={header}
                        sortField={"data_in"}
                        sortOrder={-1}
                        removableSort
                    >
                        {/* <Column selectionMode="multiple" headerStyle={{width: '3rem'}}/> */}
                        {/* <Column field="id" header="#" sortable body={idBodyTemplate}/> */}
                        {/* <Column field="guest_name" header="Name" sortable body={nameBodyTemplate}/> */}
                        <Column field="guest_lastname" header="Name" sortable body={lastnameBodyTemplate}/>
                        {/* <Column field="total_price" header="Price" body={priceBodyTemplate}/> */}
                        <Column field="data_in" header="CheckIn" sortable body={dataInBodyTemplate}/>
                        <Column field="data_out" header="CheckOut" sortable body={dataOutBodyTemplate}/>
                        <Column field="State_id" header="Status" body={statusBodyTemplate} sortable/>
                        <Column field="source_id" header="Agency" body={sourceTemplate} sortable/>
                        <Column body={actionBodyTemplate}/>
                    </DataTable>

                    <Dialog visible={reservationDialog} style={{width: '450px'}} header="Reservation Details" modal className="p-fluid" footer={reservationDialogFooter} onHide={hideDialog}>
                        {reservation.image && <img src={`assets/demo/images/reservation/${reservation.image}`} alt={reservation.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2"/>}
                        <div className="field">
                            <label htmlFor="guest_name">Guest Name</label>
                            <InputText id="guest_name" value={reservation.guest_name} onChange={(e) => onInputChange(e, 'guest_name')} required autoFocus className={classNames({'p-invalid': submitted && !reservation.guest_name})}/>
                            {submitted && !reservation.guest_name && <small className="p-invalid">Name is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="guest_lastname">Guest Last Name</label>
                            <InputText id="guest_lastname" value={reservation.guest_lastname} onChange={(e) => onInputChange(e, 'guest_lastname')} required className={classNames({'p-invalid': submitted && !reservation.guest_lastname})}/>
                            {submitted && !reservation.guest_lastname && <small className="p-invalid">Name is required.</small>}
                        </div>

                        <div className="grid formgrid">
                            <div className="field col-12 lg:col-6">
                                <label htmlFor="dates">Check In</label>
                                <Calendar
                                    dateTemplate={dateTemplate}
                                    disabledDates={invalidDates}
                                    dateFormat="dd/mm/yy"
                                    id="data_in"
                                    value={new Date(reservation.data_in ? reservation.data_in : '')}
                                    onChange={(e) => onInputDataChange(e, 'data_in')}
                                />
                            </div>
                            <div className="field col-12 lg:col-6">
                                <label htmlFor="dates">Check Out</label>
                                <Calendar
                                    dateTemplate={dateTemplate}
                                    disabledDates={invalidDates}
                                    dateFormat="dd/mm/yy" id="data_out"
                                    value={new Date(reservation.data_out ? reservation.data_out : '')}
                                    onChange={(e) => onInputDataChange(e, 'data_out')}
                                />
                            </div>
                        </div>
                        <div className="field">
                            <label htmlFor="note">Note</label>
                            <InputTextarea id="note" value={reservation.note} onChange={(e) => onInputChange(e, 'note')} required rows={3} cols={20}/>
                        </div>

                        <div className="field">
                            <label className="mb-3">Option/Reservation Status</label>
                            <div className="formgrid grid">
                                <div className="field-radiobutton col-6">
                                    <RadioButton inputId="state_id_null" name="state_id" value={null} onChange={onState_idChange} checked={reservation.state_id === null}/>
                                    <label htmlFor="state_id4">Proposta di opzione</label>
                                </div>

                                <div className="field-radiobutton col-6">
                                    <RadioButton inputId="state_id1" name="state_id" value="0" onChange={onState_idChange} checked={reservation.state_id === 0}/>
                                    <label htmlFor="state_id1">Opzione</label>
                                </div>
                                <div className="field-radiobutton col-6">
                                    <RadioButton inputId="state_id2" name="state_id" value="1" onChange={onState_idChange} checked={reservation.state_id === 1}/>
                                    <label htmlFor="state_id2">Confermata da verificare</label>
                                </div>
                                <div className="field-radiobutton col-6">
                                    <RadioButton inputId="state_id3" name="state_id" value="2" onChange={onState_idChange} checked={reservation.state_id === 2}/>
                                    <label htmlFor="state_id3">Confermata verificata</label>
                                </div>

                            </div>
                        </div>

                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="total_price">Total Price</label>
                                <InputNumber id="total_price" value={reservation.total_price} onValueChange={(e) => onInputNumberChange(e, 'total_price')} mode="currency" currency="EUR" locale="it-IT"/>
                            </div>
                            <div className="field col">
                                <label htmlFor="agency">Agency</label>
                                <Dropdown value={reservation.source_id} onChange={(e) => onInputNumberChange(e, 'source_id')} options={sourceIdValues} optionLabel="agency_name" optionValue="id" placeholder="Select"/>

                            </div>
                        </div>
                    </Dialog>

                    <Dialog visible={deleteReservationDialog} style={{width: '450px'}} header="Confirm" modal footer={deleteReservationDialogFooter} onHide={hideDeleteReservationDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{fontSize: '2rem'}}/>
                            {reservation && <span>Are you sure you want to delete <b>{reservation.name}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteReservationsDialog} style={{width: '450px'}} header="Confirm" modal footer={deleteReservationsDialogFooter} onHide={hideDeleteReservationsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{fontSize: '2rem'}}/>
                            {reservation && <span>Are you sure you want to delete the selected reservations?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
