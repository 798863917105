import React from "react";
import {GalleriaFullScreen} from "./Galleria";
export const VrOutside = () => {
  return (
    <div className="card text-center">
      <h2>Outside</h2>
      <p><strong>Park:</strong> The villa is surrounded by 5000 square meters wide park, green spaces and sheltered spaces for your cars.</p>
      <p><strong>Pool:</strong> Opened from early-June to mid-September, the squared swimming pool, dimensioned of 7 x 15 mt, has a depth variable from 90 cm to 250 cm. It is completely equipped with camp bed, deckchair, chairs, tables and shower.</p>

      <GalleriaFullScreen startPic="1" endPic="28" />

    </div>

  )
}
