import React from "react";

export const About = () => {
  return (

    <div className="card text-center">
        <h2>About</h2>
        <p>Villa Magnolia is, as the name says, a real flower. It’s a lovely building facing on the lake with a leafy garden with a lovely panoramic views on lake Como.</p>
        <p>With the village center of Oliveto Lario just a stroll away, you’re never far from services. And the area’s quaint villages and must-see sights are all within easy reach whether you strike out on your own or take the public bus from the nearby stop.</p>
    </div>

    //TODO - Mettere immagine delle facciate
  )
}
