import React, {useEffect, useState} from "react";
import {PrintMonth, PrintMonthNextYear, SetApps} from "../../../service/CalService";
import css from './Availability.module.css';
import {ReservationsService} from "../../../service/ReservationsService";


export const Availability = () => {

    const propertyId = 2; // Villa Magnolia è proprietà 2, Villa Renza 1

    const [months, setMonths] = useState([]);
    const [monthsNextYear, setMonthsNextYear] = useState([]);


    const d = new Date();
    // Dal mese 11 prendo l'anno successivo
    const year = d.getMonth() < 10 ? d.getFullYear() : d.getFullYear() + 1;

    useEffect((year) => {
        const d = new Date();
        const reservationsService = new ReservationsService();
        const getWebsiteDatesHandler = () => {
            const year = d.getMonth() < 10 ? d.getFullYear() : d.getFullYear() + 1;
            reservationsService.getWebsiteDates(year, propertyId).then(data => {
                // console.log(data);
                SetApps(data[0]['reservations'], data[0]['options'], data[1]['reservations'], data[1]['options']);

                let monthsArr = [];
                let monthsNextYearArr = [];
                for (let i = 4; i < 10; i++) {
                    const currYearMonth = PrintMonth(i);
                    const nextYearMonth = PrintMonthNextYear(i);

                    monthsArr.push(currYearMonth);
                    monthsNextYearArr.push(nextYearMonth)
                }
                setMonths(monthsArr);
                setMonthsNextYear(monthsNextYearArr);
            });
        }
        getWebsiteDatesHandler(propertyId, year);
    }, [year]);


    return (
        <div className="card">
            <div className="text-center">
                <h2>Availability</h2>
                <p>
                    Please check structure availability consulting the calender.<br/>
                    <span className={css.confirmed}>Red dates:</span> already booked - <span className={css.option}>Light blue dates:</span> optioned but not confirmed yet.
                </p>

            </div>


            <div className="grid mt-4">
                <div className="col-12 text-center">
                    <h3>{year}</h3>
                </div>

                {months.map((month, i) => (
                    <div key={i} className="col-12 xl:col-3 lg:col-3 md:col-4 sm:col-6" dangerouslySetInnerHTML={{__html: month}}/>
                ))}

                <div className="col-12 text-center mt-3">
                    <h3>{year + 1}</h3>
                </div>

                {monthsNextYear.map((month, i) => (
                    <div key={i} className="col-12 xl:col-3 lg:col-3 md:col-4 sm:col-6" dangerouslySetInnerHTML={{__html: month}}/>
                ))}
            </div>
        </div>

    )
}
