export default function authHeader() {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user && user.token) {
        return { Authorization: 'Bearer ' + user.token }; // Use this for classic Bearer authentication
        // return { "x-auth-token": user.accessToken }; // Use this in case of node.js server
    } else {
        return {};
    }
}
