import React, {useState} from 'react';
import Form from "react-validation/build/form";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import { ProgressBar } from 'primereact/progressbar';
import AuthService from "../service/auth/auth.service";
import {Message} from "primereact/message";
import { useHistory } from "react-router-dom";

export const Login = (props) => {

    const [state, setState] = useState( {
        username: "giancarlotrezzi@gmail.com",
        password: "$Monica76",
        loading: false,
        message: ""
    });

    const history = useHistory();

    const required = value => {
        if (!value) {
            return (
                <div className="alert alert-danger" role="alert">
                    This field is required!
                </div>
            );
        }
    };

    const onChangeUsername = (e) => {
        setState({...state, username: e.target.value});
    }

    const onChangePassword = (e) => {
        setState({...state, password: e.target.value});
    }


    const handleLogin = (e) => {
        e.preventDefault();

        setState({
            message: "",
            loading: true
        });


        AuthService.login(state.username, state.password).then(
            (e) => {
                // console.log(this.props);
                // this.props.history.push("/profile");

                setState({
                    loading: false
                });
                props.onSetIsLogged(true);
                history.push("/reservations");
                // window.location.reload();

            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setState({
                    loading: false,
                    message: resMessage
                });
            }
        );

    }


    return (
        <div className="grid">
            <div className="col-12 lg:col-4 lg:col-offset-4 md:col-6 md:col-offset-3">
                <div className="card p-fluid">
                    <h4>Login</h4>
                    <Form onSubmit={handleLogin}>
                        <div className="field">
                            <span className="p-input-icon-left">
                                <i className="pi pi-user"/>
                                <InputText
                                    id="username"
                                    name="username"
                                    type="text"
                                    className="form-control"
                                    placeholder="Username"
                                    onChange={onChangeUsername}
                                    validations={[required]}
                                />
                            </span>
                        </div>
                        <div className="field">
                            <span className="p-input-icon-left">
                                <i className="pi pi-key"/>
                                <InputText
                                   id="password"
                                   name="password"
                                   type="password"
                                   className="form-control"
                                   placeholder="Password"
                                   onChange={onChangePassword}
                                   validations={[required]}
                                />
                            </span>
                        </div>
                        <Button label="Submit" disabled={state.loading} className="mb-3"/>
                        {state.loading && (
                            <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
                        )}

                        {state.message && (
                            <Message severity="error" text="Login error" />
                        )}
                    </Form>
                </div>

            </div>
        </div>
    );
}
