const d = new Date();
const year = d.getMonth() < 10 ? d.getFullYear() : d.getFullYear() + 1;

const cssOptionBg = "#C0EAFF";
const cssConfirmedBg = "#FFB9B9";

// Confirmed current year
let apps = [];

// Options current year
let apps2 = [];

// Confirmed next year
let appsNextYear = [];

// Options next year
let appsNextYear2 = [];


export const SetApps = (getApps, getApps2, getAppsNextYear, getAppsNextYear2) => {
  apps = [...getApps];
  apps2 = [...getApps2];
  appsNextYear = [...getAppsNextYear];
  appsNextYear2 = [...getAppsNextYear2];
}

const nextYear = year + 1;
let dayCounts = [];
let dayCountsNextYear = [];
let firstDays = [];
let firstDaysNextYear = [];
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const getFirstDays = (year) => {
  const arr = [];
  for (let i = 0; i <= 11; i++) {
    let myDate = new Date();
    let giorno = 0;
    myDate.setFullYear(year);
    myDate.setMonth(i);
    myDate.setDate(1);

    if (myDate.getDay() !== 6) {
      giorno = myDate.getDay() + 1;
    }
    arr.push(giorno);
  }
  return arr;
}

const leapYear = (year) => {
  return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
}

const CheckDate = (month, dayno) => {
  var retval = String(dayno);
  var m = month + 1;

  // PRENOTAZIONI
  for (var app = 0; app < apps.length; app++) {
    if (m === apps[app][1]) //first month
    {
      if (apps[app][3] - apps[app][1] > 0) {
        if (dayno >= apps[app][0]) {
          retval = "<div style='background-color: "+ cssConfirmedBg + "' title='" + apps[app][4] + "'>" + dayno + "</div>";
        }
      } else {
        if (dayno >= apps[app][0] && dayno <= apps[app][2]) {
          retval = "<div style='background-color: "+ cssConfirmedBg + "' title='" + apps[app][4] + "'>" + dayno + "</div>";
        }
      }
    } else if (m === apps[app][3]) // second month
    {
      if (dayno <= apps[app][2]) {
        retval = "<div style='background-color: "+ cssConfirmedBg + "' title='" + apps[app][4] + "'>" + dayno + "</div>";
      }
    } else if (m > apps[app][1] && m < apps[app][3]) {
      retval = "<div style='background-color: "+ cssConfirmedBg + "' title='" + apps[app][4] + "'>" + dayno + "</div>";
    }
  }

  // OPZIONI
  for (var app2 = 0; app2 < apps2.length; app2++) {
    if (m === apps2[app2][1]) //first month
    {
      if (apps2[app2][3] - apps2[app2][1] > 0) {
        if (dayno >= apps2[app2][0]) {
          retval = "<div style='background-color: " + cssOptionBg + "' title='" + apps2[app2][4] + "'>" + dayno + "</div>";
        }
      } else {
        if (dayno >= apps2[app2][0] && dayno <= apps2[app2][2]) {
          retval = "<div style='background-color: " + cssOptionBg + "' title='" + apps2[app2][4] + "'>" + dayno + "</div>";
        }
      }
    } else if (m === apps2[app2][3]) // second month
    {
      if (dayno <= apps2[app2][2]) {
        retval = "<div style='background-color: " + cssOptionBg + "' title='" + apps2[app2][4] + "'>" + dayno + "</div>";
      }
    } else if (m > apps2[app2][1] && m < apps2[app2][3]) {
      retval = "<div style='background-color: " + cssOptionBg + "' title='" + apps2[app2][4] + "'>" + dayno + "</div>";
    }
  }

  return retval;
}

const PrintWeekYear = (monthno, start, min, max) => {
  let day = '';
  var d;
  var desc;
  for (var j = 0; j < 7; j++) {
    day += "<td>";
    d = start + j;
    if (d >= min && d < max + min) {
      desc = CheckDate(monthno, d - min + 1);
      day += desc;
    }
    day += "</td>";
  }
  return day;
}


/** NEXT YEAR **/

const CheckDateNextYear = (month, dayno) => {
  var retval = String(dayno);
  var m = month + 1;

  // PRENOTAZIONI
  for (let app = 0; app < appsNextYear.length; app++) {
    if (m === appsNextYear[app][1]) //first month
    {
      if (appsNextYear[app][3] - appsNextYear[app][1] > 0) {
        if (dayno >= appsNextYear[app][0]) {
          retval = "<div style='background-color: "+ cssConfirmedBg + "' title='" + appsNextYear[app][4] + "'>" + dayno + "</div>";
        }
      } else {
        if (dayno >= appsNextYear[app][0] && dayno <= appsNextYear[app][2]) {
          retval = "<div style='background-color: "+ cssConfirmedBg + "' title='" + appsNextYear[app][4] + "'>" + dayno + "</div>";
        }
      }
    } else if (m === appsNextYear[app][3]) // second month
    {
      if (dayno <= appsNextYear[app][2]) {
        retval = "<div style='background-color: "+ cssConfirmedBg + "' title='" + appsNextYear[app][4] + "'>" + dayno + "</div>";
      }
    } else if (m > appsNextYear[app][1] && m < appsNextYear[app][3]) {
      retval = "<div style='background-color: "+ cssConfirmedBg + "' title='" + appsNextYear[app][4] + "'>" + dayno + "</div>";
    }
  }

  // OPZIONI
  for (var app = 0; app < appsNextYear2.length; app++) {
    if (m === appsNextYear2[app][1]) //first month
    {
      if (appsNextYear2[app][3] - appsNextYear2[app][1] > 0) {
        if (dayno >= appsNextYear2[app][0]) {
          retval = "<div style='background-color: " + cssOptionBg + "' title='" + appsNextYear2[app][4] + "'>" + dayno + "</div>";
        }
      } else {
        if (dayno >= appsNextYear2[app][0] && dayno <= appsNextYear2[app][2]) {
          retval = "<div style='background-color: " + cssOptionBg + "' title='" + appsNextYear2[app][4] + "'>" + dayno + "</div>";
        }
      }
    } else if (m === appsNextYear2[app][3]) // second month
    {
      if (dayno <= appsNextYear2[app][2]) {
        retval = "<div style='background-color: " + cssOptionBg + "' title='" + appsNextYear2[app][4] + "'>" + dayno + "</div>";
      }
    } else if (m > appsNextYear2[app][1] && m < appsNextYear2[app][3]) {
      retval = "<div style='background-color: " + cssOptionBg + "' title='" + appsNextYear2[app][4] + "'>" + dayno + "</div>";
    }
  }

  return retval;
}


export const PrintMonthNextYear = (month) => {

  firstDaysNextYear = getFirstDays(nextYear);

  if (leapYear(nextYear)) {
    dayCountsNextYear = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]; //for leap years, remember to set february to 29 days
  } else {
    dayCountsNextYear = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]; //for leap years, remember to set february to 29 days
  }

  let table = '';
  var done = false;
  var day = 0;


  table += "<center><table className='inner'><caption><b>" + months[month] + "</b></caption><thead>";
  table += "<th>Sat</th><th>Sun</th><th>Mon</th><th>Tue</th><th>Wed</th><th>Thu</th><th>Fri</th></thead>";

  while (!done) {
    table += "<tr>";
    table += PrintWeekYearNextYear(month, day, firstDaysNextYear[month], dayCountsNextYear[month], table);
    table += "</tr>";

    day = day + 7;
    if (day > dayCountsNextYear[month] + firstDaysNextYear[month]) {
      done = true;
    }
  }
  table += "</tbody></table></center><p>";
  return table;
}

const PrintWeekYearNextYear = (monthno, start, min, max) => {
  let day = '';
  var d;
  var desc;
  for (var j = 0; j < 7; j++) {
    day += "<td>";
    d = start + j;
    if (d >= min && d < max + min) {
      desc = CheckDateNextYear(monthno, d - min + 1);
      day += desc;
    }
    day += "</td>";
  }
  return day;
}




export const PrintMonth = (month) => {
    firstDays = getFirstDays(year);

    if (leapYear(year)) {
      dayCounts = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]; //for leap years, remember to set february to 29 days
    } else {
      dayCounts = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]; //for leap years, remember to set february to 29 days
    }

    // Giorni: Sabato=0, Domenica=1, Lunedi=2, Martedi=3, Mercoledi=4, Giovedi=5, Venerdi=6


    let table = '';
    var done = false;
    var day = 0;


    table += "<center><table className='inner'><caption><b>" + months[month] + "</b></caption><thead>";
    table += "<th>Sat</th><th>Sun</th><th>Mon</th><th>Tue</th><th>Wed</th><th>Thu</th><th>Fri</th></thead>";

    while (!done) {
      table += "<tr>";
      table += PrintWeekYear(month, day, firstDays[month], dayCounts[month], table);
      table += "</tr>";

      day = day + 7;
      if (day > dayCounts[month] + firstDays[month]) {
        done = true;
      }
    }
    table += "</tbody></table></center><p>";
    return table;
}



