import React from "react";
import {Header} from "./components/Header";
import {About} from "./components/About";
import {Outside} from "./components/Outside";
import {Inside} from "./components/Inside";
import {Availability} from "./components/Availability";
import {FindUs} from "./components/FindUs";
import {Footer} from "./components/Footer";
// import {Amenities} from "./components/Amenities";

export const Magnolia = ({layoutColorMode}) => {


    return (
        <div>
            <Header layoutColorMode={layoutColorMode}/>
            <About/>
            <Outside/>
            <Inside/>
            <Availability/>
            {/*<Amenities />*/}
            <FindUs/>
            <Footer/>
        </div>
    );
}
