import React from "react";
import {VrHeader} from "./components/VrHeader";
import {VrAbout} from "./components/VrAbout";
import {VrOutside} from "./components/VrOutside";
import {VrInside} from "./components/VrInside";
import {VrAvailability} from "./components/VrAvailability";
import {VrFindUs} from "./components/VrFindUs";
import {VrFooter} from "./components/VrFooter";
// import {VrAmenities} from "./components/VrAmenities";

export const Homepage = ({layoutColorMode}) => {


    return (
        <div>
            <VrHeader layoutColorMode={layoutColorMode}/>
            <VrAbout/>
            <VrOutside/>
            <VrInside/>
            <VrAvailability/>
            {/*<VrAmenities />*/}
            <VrFindUs/>
            <VrFooter/>
        </div>
    );
}
