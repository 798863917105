import React from "react";
import {GalleriaFullScreen} from "./Galleria";

export const Inside = () => {
    return (
        <div className="card text-center">
            <h2>Inside</h2>

            <p><strong>Ground Floor:</strong> The ground floor has an equipped kitchen, a wood table and access to the garden for long lazy lake-view meals and barbeque experience, while the open-plan living/dining space has ample space and entertainments for families, one bathroom, one service room with washing machine.</p>

            <p><strong>First Floor:</strong> First floor is composed by a spacious kitchen equipped with all the tools and nice terrace with a small table to enjoy breakfast overlooking the lake, connected with a bright dinner room is characterized by a large table and the living room, complete of big sofa in front of the terrace with lake view.
                On the room you can also find a TV.
                On this floor there is also a small bathroom.
            </p>

            <p><strong>Second Floor:</strong> On second floor there is the sleeping area, from every rooms you can look up to the amazing overview.
                The floor is composed by 2 master bedrooms and 1 twin bedrooms, and finally one bunk bedroom, on this floor there are 2 bathrooms equipped with a shower and one en-suite toilette in the twin bedroom.
            </p>

            <p><strong>Penthouse Floor:</strong> One of Villa Magnolia’s most popular ‘treats’ is the penthouse floor with its sloped timber-beamed ceilings and lake views. The penthouse has a equipped kitchen with a large table in a middle, a big sofa, a double bedroom and one bathroom with its own shower making this floor into a private suite.
            </p>

            <GalleriaFullScreen startPic="50" endPic="53"/>

        </div>
    )
}
