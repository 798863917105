import React from 'react';

export const VrFooter = (props) => {

    return (
        <div className="layout-footer">
            {/*<img src={props.layoutColorMode === 'light' ? 'assets/layout/images/logo-dark.svg' : 'assets/layout/images/logo-white.svg'} alt="Logo" height="20" className="mr-2" />*/}
          <small>
            2009-{new Date().getFullYear()} © Villa Renza Oliveto Lario
            <br />Via Cadorna, 80
              <a href="mailto:info@villarenza.com"  className="ml-1 mr-1" style={{ color: "var(--surface-900)" }}> info@villarenza.com </a>
            <br />CIR: 097060-LNI-00021</small>
        </div>
    );
}
