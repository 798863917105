import React from "react";
import {GalleriaFullScreen} from "./Galleria";
export const Outside = () => {
  return (
    <div className="card text-center">
      <h2>Outside</h2>
      <p><strong>Garden:</strong> The villa is surrounded by 8600 ft² (800 m²) of garden, green spaces and 2 cars private park.</p>
      <p><strong>Lake access:</strong> We have direct access to the lake from our garden and there's a solarium equipped with deckchair, chairs and shower.</p>

      <GalleriaFullScreen startPic="01" endPic="01" />

    </div>

  )
}
