import React from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import classNames from 'classnames';
import authService from './service/auth/auth.service';

export const AppTopbar = ({
                              onSetIsLogged,
                              isLoggedIn,
                              userInfo,
                              setUserInfo,
                              onToggleMenuClick,
                              layoutColorMode,
                              mobileTopbarMenuActive,
                              onMobileTopbarMenuClick,
                              onMobileSubTopbarMenuClick
                          }) => {

    const history = useHistory();
    const location = useLocation();


    // useEffect(() => {
    //     setUserInfo(authService.getUserInfo());
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isLoggedIn, layoutColorMode])


    const handleLogout = () => {
        authService.logout();
        history.push("/login");
        setUserInfo(null);
        onSetIsLogged(false)
    }

    const goToReservations = () => {
        console.log('Go to reservations');
    }

    return (


                    <div className="layout-topbar">

                        {location.pathname === "/Magnolia" ? (
                            <Link to="/Magnolia" className="layout-topbar-logo">
                                <img src='assets/Magnolia/logo_purple.svg' alt="logo"/>
                            </Link>
                        ) : (
                            <Link to="/" className="layout-topbar-logo">
                                <img src={layoutColorMode === 'light' ? 'assets/layout/images/logo-dark.svg' : 'assets/layout/images/logo-white.svg'} alt="logo"/>
                            </Link>

                        )}




                        <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={onMobileTopbarMenuClick}>
                            <i className="pi pi-ellipsis-v"/>
                        </button>

                        <ul className={classNames("layout-topbar-menu lg:flex origin-top", {'layout-topbar-menu-mobile-active': mobileTopbarMenuActive})}>
                            {/*<li>*/}
                            {/*    <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>*/}
                            {/*        <i className="pi pi-calendar"/>*/}
                            {/*        <span>Events</span>*/}
                            {/*    </button>*/}
                            {/*</li>*/}
                            {/*<li>*/}

                            {/*    <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>*/}
                            {/*        <i className="pi pi-cog"/>*/}
                            {/*        <span>Settings</span>*/}
                            {/*    </button>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*    <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>*/}
                            {/*        <i className="pi pi-user"/>*/}
                            {/*        <span>Profile</span>*/}
                            {/*    </button>*/}
                            {/*</li>*/}
                            {/*
                            <li>
                                <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={onToggleMenuClick}>
                                    <i className="pi pi-bars"/>
                                </button>
                            </li>
                            */}
                            <li>
                                {/*{isLoggedIn === true || userInfo?.role*/}
                                {isLoggedIn === true
                                    ?
                                    <>
                                        <Link to="/reservations">
                                            <button className="p-link layout-topbar-button" title="Reservations">
                                                <i className="pi pi-calendar"  />
                                                <span>Reservations</span>
                                            </button>
                                        </Link>

                                        <button className="p-link layout-topbar-button" onClick={handleLogout} title={`Logout: ${userInfo?.email}`}>
                                            <i className="pi pi-sign-out"  />
                                            <span>Logout</span>
                                        </button>
                                    </>
                                    :
                                    <Link to='/login'>
                                        <button className="p-link layout-topbar-button">
                                            <i className="pi pi-key"/>
                                            <span>Login</span>
                                        </button>
                                    </Link>
                                }
                            </li>
                        </ul>
                    </div>



    )
}
