import React from "react";
import {GalleriaFullScreen} from "./Galleria";

export const VrInside = () => {
    return (
        <div className="card text-center">
            <h2>Inside</h2>

            <p><strong>Ground Floor:</strong> Ground floor is composed by a wide living room, complete of big sofa in front of the chimney and television, and, if you want to entertain yourself, behind the sofa you can find a big snooker.
                Next to the spacious kitchen, the wide dinner room is characterized by a large wooden table.
                From dinner room and kitchen you can reach the balcony where you can look up the beautiful landscape comfortably sitted on chairs and tables you can find there.
            </p>

            <p><strong>First Floor:</strong> On first floor there is the spleeping area, composed by 3 master bedrooms and 2 single bedrooms which can be transformed in double rooms and, finally, 3 bathrooms equipped with a shower.
                From every rooms you can look up to the amazing overview, moreover from 3 rooms you can reach the big balcony.
            </p>

            <p><strong>Penthouse Floor:</strong> Second floor is characterized by a wide apartment quite independent, composed by a master bedroom, single room which could be transformed in double bedroom, a bathroom equipped with a bath and a shower, a room equipped with
                a desk and a sofa bed, a living room and a kitchen.
                In the wide balcony, which could permit you to sunbath, you can find even there a table for your dinners.
            </p>

            <p><strong>Basement:</strong> In the basement you can find, besides techical rooms, even one master bedroom, two bathrooms, one equipped with a shower and one equipped with a bath-tube, a laundry room complete of washing-machine, a dryer, clothes
                horse, iron equipment and also our Wine Cellar, where you can find some local products to taste.
            </p>

            <GalleriaFullScreen startPic="29" endPic="60"/>

        </div>
    )
}
