import React from "react";
import css from './VrHeader.module.css';

export const VrHeader = ({layoutColorMode}) => {



  return (
    <div className={"card flex " + css.header}>
        <div className="col-12 xl:col-3 lg:col-4 md:col-4 sm:col-6">
          <div className={css.cardHeader + " card text-center"}>
            <img src={layoutColorMode === 'light' ? 'assets/layout/images/logo-dark.svg' : 'assets/layout/images/logo-white.svg'}
                 alt="Logo Villa Renza" className="img-fluid" style={{ width: "100%" }}/>
            <p>Luxury Vacation Rental</p>
            <small>2009-{new Date().getFullYear()} © Villa Renza</small><br/>
            <small>info@villarenza.com</small>
          </div>
        </div>
    </div>

  )
}
