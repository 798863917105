import React from "react";

export const VrAbout = () => {
  return (

    <div className="card text-center">
      <h2>About</h2>
      <p>Villa Renza was built in the early twentieth century. It has been restored first on 1970 changing completely its shape,
        by the first owner's family.</p>
      <p>On 2002 Trezzi's family bought the Villa. On 2008 the Villa has been restored again with green and modern solution but
        maintaining its original splendor. Its perfect position right on the lake allows you to enjoy breathtaking views while
        relaxing poolside in the privacy of the extensive and beautifully maintained villa gardens.</p>
      <p>It is a beautiful base for exploring the Lake Como area. On the water there is a private landing stage where guests can
        board a private boat for wonderful tours, visiting villas and villages scattered on the lake's shores. For city lovers
        Como, Lecco, Bergamo, Milano, Lugano are all within a convenient 90 minute or less drive from Villa Renza.</p>
    </div>

    //TODO - Mettere immagine delle facciate
  )
}
