import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import {Route, Switch} from "react-router-dom";
import {CSSTransition} from 'react-transition-group';

import {AppTopbar} from './AppTopbar';
import {AppFooter} from './AppFooter';
import {AppMenu} from './AppMenu';
import {AppConfig} from './AppConfig';

import {Dashboard} from './components/Dashboard';
import {ButtonDemo} from './components/ButtonDemo';
import {ChartDemo} from './components/ChartDemo';
import {Documentation} from './components/Documentation';
import {FileDemo} from './components/FileDemo';
import {FloatLabelDemo} from './components/FloatLabelDemo';
import {FormLayoutDemo} from './components/FormLayoutDemo';
import {InputDemo} from './components/InputDemo';
import {ListDemo} from './components/ListDemo';
import {MenuDemo} from './components/MenuDemo';
import {MessagesDemo} from './components/MessagesDemo';
import {MiscDemo} from './components/MiscDemo';
import {OverlayDemo} from './components/OverlayDemo';
import {PanelDemo} from './components/PanelDemo';
import {TableDemo} from './components/TableDemo';
import {TreeDemo} from './components/TreeDemo';
import {InvalidStateDemo} from './components/InvalidStateDemo';

import {Crud} from './pages/Crud';
import {EmptyPage} from './pages/EmptyPage';
import {TimelineDemo} from './pages/TimelineDemo';

import {Login} from './features/Login';
import {Reservations} from './features/Reservations';
import CookieConsent from "react-cookie-consent";

import PrimeReact from 'primereact/api';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './layout/flags/flags.css';
import './layout/layout.scss';
import './App.scss';
import authService from "./service/auth/auth.service";
import {Homepage} from "./features/Homepage/Homepage";
import GuardedRoute from "./features/GuardRoute";
import NotFound from "./pages/NotFound";
import {Magnolia} from "./features/Magnolia/Magnolia";

const App = () => {

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [layoutMode, setLayoutMode] = useState('static');
    const [layoutColorMode, setLayoutColorMode] = useState('light')
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(true); // true menu chiuso, false aperto
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);

    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
        getTokenFromLocalStorage();
    }, [mobileMenuActive]);

    const getTokenFromLocalStorage = () => {
        const tokenInfo = authService.getUserInfo();
        const currentUser = authService.getCurrentUser();
        if (tokenInfo !== null) {
            setUserInfo(tokenInfo);
            setIsLoggedIn(true);
        }

    }

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value)
    }

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode)
    }

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode)
    }

    const onWrapperClick = () => {
        onMainChange();
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    }

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === 'static') {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    }

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }
    const isDesktop = () => {
        return window.innerWidth >= 992;
    }

    const menu = [
        {
            label: 'Home',
            items: [
                {label: 'Login', icon: 'pi pi-fw pi-key', to: '/login'},
                {label: 'Home', icon: 'pi pi-fw pi-home', to: '/'},
                {label: 'Reservations', icon: 'pi pi-fw pi-list', to: '/reservations'}
            ]
        },
        {
            label: 'UI Kit', icon: 'pi pi-fw pi-sitemap',
            items: [
                {label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/dashboard'},
                {label: 'Form Layout', icon: 'pi pi-fw pi-id-card', to: '/formlayout'},
                {label: 'Input', icon: 'pi pi-fw pi-check-square', to: '/input'},
                {label: "Float Label", icon: "pi pi-fw pi-bookmark", to: "/floatlabel"},
                {label: "Invalid State", icon: "pi pi-fw pi-exclamation-circle", to: "invalidstate"},
                {label: 'Button', icon: 'pi pi-fw pi-mobile', to: '/button'},
                {label: 'Table', icon: 'pi pi-fw pi-table', to: '/table'},
                {label: 'List', icon: 'pi pi-fw pi-list', to: '/list'},
                {label: 'Tree', icon: 'pi pi-fw pi-share-alt', to: '/tree'},
                {label: 'Panel', icon: 'pi pi-fw pi-tablet', to: '/panel'},
                {label: 'Overlay', icon: 'pi pi-fw pi-clone', to: '/overlay'},
                {label: 'Menu', icon: 'pi pi-fw pi-bars', to: '/menu'},
                {label: 'Message', icon: 'pi pi-fw pi-comment', to: '/messages'},
                {label: 'File', icon: 'pi pi-fw pi-file', to: '/file'},
                {label: 'Chart', icon: 'pi pi-fw pi-chart-bar', to: '/chart'},
                {label: 'Misc', icon: 'pi pi-fw pi-circle-off', to: '/misc'},
            ]
        },
        {
            label: 'Pages', icon: 'pi pi-fw pi-clone',
            items: [
                {label: 'Crud', icon: 'pi pi-fw pi-user-edit', to: '/crud'},
                {label: 'Timeline', icon: 'pi pi-fw pi-calendar', to: '/timeline'},
                {label: 'Empty', icon: 'pi pi-fw pi-circle-off', to: '/empty'}
            ]
        },
        {
            label: 'Menu Hierarchy', icon: 'pi pi-fw pi-search',
            items: [
                {
                    label: 'Submenu 1', icon: 'pi pi-fw pi-bookmark',
                    items: [
                        {
                            label: 'Submenu 1.1', icon: 'pi pi-fw pi-bookmark',
                            items: [
                                {label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-bookmark'},
                                {label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-bookmark'},
                                {label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-bookmark'},
                            ]
                        },
                        {
                            label: 'Submenu 1.2', icon: 'pi pi-fw pi-bookmark',
                            items: [
                                {label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-bookmark'},
                                {label: 'Submenu 1.2.2', icon: 'pi pi-fw pi-bookmark'}
                            ]
                        },
                    ]
                },
                {
                    label: 'Submenu 2', icon: 'pi pi-fw pi-bookmark',
                    items: [
                        {
                            label: 'Submenu 2.1', icon: 'pi pi-fw pi-bookmark',
                            items: [
                                {label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-bookmark'},
                                {label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-bookmark'},
                                {label: 'Submenu 2.1.3', icon: 'pi pi-fw pi-bookmark'},
                            ]
                        },
                        {
                            label: 'Submenu 2.2', icon: 'pi pi-fw pi-bookmark',
                            items: [
                                {label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-bookmark'},
                                {label: 'Submenu 2.2.2', icon: 'pi pi-fw pi-bookmark'}
                            ]
                        }
                    ]
                }
            ]
        },
        {
            label: 'Get Started',
            items: [
                {
                    label: 'Documentation', icon: 'pi pi-fw pi-question', command: () => {
                        window.location = "#/documentation"
                    }
                },
                {
                    label: 'View Source', icon: 'pi pi-fw pi-search', command: () => {
                        window.location = "https://github.com/primefaces/sakai-react"
                    }
                }
            ]
        }
    ];

    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const onMainChange = () => {
        if (userInfo?.role !== authService.getUserInfo()?.role) {
            setUserInfo(authService.getUserInfo());
        }
    }

    const onSetIsLogged = (e) => {
        setIsLoggedIn(e);
    }

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false,
        'layout-theme-light': layoutColorMode === 'light'
    });


    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            <AppTopbar
                onSetIsLogged={onSetIsLogged}
                isLoggedIn={isLoggedIn}
                userInfo={userInfo}
                setUserInfo={(e) => setUserInfo(e)}
                onToggleMenuClick={onToggleMenuClick}
                layoutColorMode={layoutColorMode}
                mobileTopbarMenuActive={mobileTopbarMenuActive}
                onMobileTopbarMenuClick={onMobileTopbarMenuClick}
                onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
            />

            <div className="layout-sidebar" onClick={onSidebarClick}>
                <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode}/>
            </div>


            <div className="layout-main-container">
                <div className="layout-main">
                    <Switch>
                    <Route path="/" exact>
                        <Homepage
                            layoutColorMode={layoutColorMode}
                        />
                    </Route>

                    <Route path="/magnolia" exact>
                        <Magnolia
                            layoutColorMode={layoutColorMode}
                        />
                    </Route>

                    <Route path="/dashboard" exact component={Dashboard}/>
                    {/*<Route path="/login"  element={<Login onColorModeChange={onColorModeChange} />}/>*/}
                    {/*<Route path="/reservations" exact component={Reservations}/>*/}
                    <GuardedRoute path='/reservations' exact component={Reservations} auth={isLoggedIn}/>
                    {/*<Route path="/homepage" exact component={Homepage} />*/}
                    <Route path="/formlayout" component={FormLayoutDemo}/>
                    <Route path="/input" component={InputDemo}/>
                    <Route path="/floatlabel" component={FloatLabelDemo}/>
                    <Route path="/invalidstate" component={InvalidStateDemo}/>
                    <Route path="/button" component={ButtonDemo}/>
                    <Route path="/table" component={TableDemo}/>
                    <Route path="/list" component={ListDemo}/>
                    <Route path="/tree" component={TreeDemo}/>
                    <Route path="/panel" component={PanelDemo}/>
                    <Route path="/overlay" component={OverlayDemo}/>
                    <Route path="/menu" component={MenuDemo}/>
                    <Route path="/messages" component={MessagesDemo}/>
                    <Route path="/file" component={FileDemo}/>
                    <Route path="/chart" component={ChartDemo}/>
                    <Route path="/misc" component={MiscDemo}/>
                    <Route path="/timeline" component={TimelineDemo}/>
                    <Route path="/crud" component={Crud}/>
                    <Route path="/empty" component={EmptyPage}/>
                    <Route path="/documentation" component={Documentation}/>

                    <Route path='/login'>
                        <Login
                            onSetIsLogged={onSetIsLogged}
                        />
                    </Route>

                    <Route path='*' exact={true} component={NotFound} />
                    </Switch>

                </div>
                {/* <AppFooter layoutColorMode={layoutColorMode}/> */}
            </div>

            <AppConfig isLoggedIn={isLoggedIn} rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                       layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode}
                       onColorModeChange={onColorModeChange}/>

            <CSSTransition classNames="layout-mask" timeout={{enter: 200, exit: 200}} in={mobileMenuActive} unmountOnExit>
                <div className="layout-mask p-component-overlay"></div>
            </CSSTransition>

            <CookieConsent
                location="bottom"
                buttonText="I understand"
                cookieName="VillaRenzaAcceptCookie"
                style={{background: "#2B373B"}}
                buttonStyle={{color: "#4e503b", fontSize: "13px"}}
                expires={30}
                // acceptOnScroll="true"
                // acceptOnScrollPercentage={50}
                // debug="true"
                //overlay={true}

                onAccept={(acceptedByScrolling) => {
                    if (acceptedByScrolling) {
                        // triggered if user scrolls past threshold
                        console.log('accepted by scroll');
                    } else {
                        console.log('accepted by click');
                    }
                }}

            >
                This website uses cookies to enhance the user experience.{" "}
            </CookieConsent>


        </div>
    );

}

export default App;
