import axios from "axios";
import jwt from 'jwt-decode';

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

const signup = (name, email, password) => {
    return axios
        .post(BASE_API_URL + "vr/Auth/Register", {
            name,
            email,
            password,
        })
        .then((response) => {
            if (response.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data));
                localStorage.setItem("tokenInfo", JSON.stringify(jwt(response.data.token)));
            }

            return response.data;
        });
};

const login = (email, password) => {
    return axios
        .post(BASE_API_URL + "vr/Auth/Login", {
            email,
            password,
        })
        .then((response) => {
            if (response.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data));
                localStorage.setItem("tokenInfo", JSON.stringify(jwt(response.data.token)));
            }

            return response.data;
        });
};

const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("tokenInfo");
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

const getUserInfo = () => {
    return JSON.parse(localStorage.getItem("tokenInfo"));
};


const authService = {
    signup,
    login,
    logout,
    getCurrentUser,
    getUserInfo
};

export default authService;
