import React from 'react';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';

const NotFound = () => (

<div className="card grid grid-nogutter surface-0 text-800">
    <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
        <section>
            <span className="block text-8xl font-bold mb-1">Oops!</span>
            <div className="text-3xl text-primary font-bold mb-3">404 - PAGE NOT FOUND</div>
            <p className="mt-0 mb-4 text-700 line-height-3">The page you are looking for might have been removed, had its name changed or is temporarily unavailable.</p>
            <Link to="/">
                <Button label="Go to homepage" type="button" className="p-button-outlined" />
            </Link>
        </section>
    </div>
    <div className="col-12 md:col-6 overflow-hidden">
        <img src="assets/FotoVillaRenza/24_Villa_Renza_xxl.jpg" alt="Villa Renza 404" className="hidden md:ml-auto md:block " style={{ height: '400px', clipPath: 'polygon(8% 0, 100% 0%, 100% 100%, 0 100%)' }} />
    </div>
</div>
);

export default NotFound;
