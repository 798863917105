import React from "react";

export const VrFindUs = () => {
  return (
    <div className="card text-center">


          <h2>Find Us</h2>
          <p>Villa Renza is situaded in the heart of Como's lake and it is
            easily reachable by car.
            <br/>You can reach us even by bus from main railway stations in Milan, Como, Asso and Lecco.
            <br/>The best solution is still the taxi or if you want to visit our neighborhood, rent a car is highly suggested.
            <br/>Anyway we'll be glad to provide all the assistance you need from the airport and back and even to move here
            around.</p>

        <iframe title="Google map" className="effect6" width="100%" height="450" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"
                src="https://maps.google.it/maps?f=q&amp;source=s_q&amp;hl=it&amp;geocode=&amp;q=Via+Cadorna+Vassena,+80,+Vassena+LC&amp;aq=0&amp;oq=Vi&amp;sll=45.686096,9.238558&amp;sspn=0.056782,0.13484&amp;ie=UTF8&amp;hq=&amp;hnear=Via+Cadorna+Vassenia,+80,+Vassena,+Lecco,+Lombardia&amp;t=m&amp;ll=45.95805,9.284134&amp;spn=0.262663,0.823975&amp;z=11&amp;iwloc=A&amp;output=embed"></iframe>


    </div>
  )
}
